import React, { useEffect, useState } from "react";
import { IoNotificationsOutline, IoSearchOutline } from "react-icons/io5";
import { Image } from "semantic-ui-react";
import styled from "styled-components";
import dIcon from "../assets/d_icon.svg";
import proPic from "../assets/r.png";
import { useAppContext } from "../context/app_context";
import { useUserContext } from "../context/user_context";
import AuthUser from "./AuthUser";
import MenuDropdownProfile from "./MenuDropdownProfile";
const NotificationButton = (props) => {
  const { http } = AuthUser();
  const { selectedFactoryId, changeSelectedFactoryId } = useUserContext();
  const [factories, setFactories] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const { closeSidebar } = useAppContext();

  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    if (user) {
      const myArray = Object.entries(user.factories).map(([key, value]) => ({
        name: value,
        id: key,
      }));
      setFactories(Object.values(myArray));
    }
  }, [selectedFactoryId]);

  const handleChange = (e) => {
    changeSelectedFactoryId(e);
    closeSidebar();
  };

  return (
    <Wrapper className="notification-btn-wrapper">
      <div className="select-bar">
        <div className="d-flex justify-content-around">
          <IoNotificationsOutline size={30} />
          <IoSearchOutline size={30} />
          <Image
            src={proPic}
            style={{ cursor: "pointer" }}
            avatar
            onClick={(e) => setShowMenu(!showMenu)}
          />
        </div>
        {showMenu && (
          <MenuDropdownProfile
            setShowMenu={setShowMenu}
            windowWidth={props.windowWidth}
          />
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .select-input {
    border: none;
    color: var(--clr-grey-1);
    background-color: transparent;
    width: 140px;
    cursor: pointer;
    padding: 5px;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent url(${dIcon}) no-repeat 100% center;
    /* background: transparent url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat; */
    background-position: right -5px top 50%;
    /* background: url(https://stackoverflow.com/favicon.ico) 96% / 15% no-repeat; */
  }

  small {
    color: var(--clr-grey-1);
  }
  .auth-btn img {
    border: 2px solid var(--clr-primary-1);
    width: 25px;
    height: 25px;
    border-radius: 100%;
    padding: 2px;
    margin: auto 5px;
  }
  .select-bar svg, img {
    border: 2px solid var(--clr-primary-1);
    width: 25px;
    height: 25px;
    border-radius: 100%;
    text-align: center;
    padding: 2px;
    margin: auto 5px;
  }
  @media screen and (max-width: 575px) {
    .select-bar svg {
      border: 0;
    }
  }
`;

export default NotificationButton;
